<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8" lg="6" xl="4">
        <h1
          :class="{
            'my-12': !emailConfirmationToken,
            'mt-12 mb-4': emailConfirmationToken
          }"
          class="text-h6 font-weight-light text-uppercase text-center"
        >
          Login
        </h1>
        <p v-if="emailConfirmationToken" class="text-body-2 text-center mb-6">
          Your email has been confirmed and your account is now active, please
          login.
        </p>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="noop"
        >
          <v-text-field
            v-model.trim="email"
            :rules="emailRules"
            label="Enter your e-mail address"
            outlined
            validate-on-blur
            required
            @keydown.space.prevent
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            :append-icon="
              showPassword ? 'fal fa-eye fa-sm' : 'fal fa-eye-slash fa-sm'
            "
            :type="showPassword ? 'text' : 'password'"
            label="Enter your password"
            min="8"
            outlined
            validate-on-blur
            required
            @click:append="() => (showPassword = !showPassword)"
          ></v-text-field>
          <v-btn
            :loading="processing"
            dark
            depressed
            type="submit"
            x-large
            block
            class="text-body-2 text-uppercase ma-0 h-60"
            @click="submit"
            >Sign in</v-btn
          >
        </v-form>
        <v-row class="mt-2">
          <v-col class="my-2" cols="12">
            <p class="mb-0 text-center text-uppercase text-body-2">or</p>
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn
              :loading="processing"
              block
              x-large
              depressed
              color="google"
              dark
              class="text-body-2 text-uppercase ma-0 h-60"
              @click="oauthSignup('google')"
            >
              <v-icon>fab fa-google</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn
              :loading="processing"
              block
              x-large
              depressed
              color="facebook"
              dark
              class="text-body-2 text-uppercase ma-0 h-60"
              @click="oauthSignup('facebook')"
            >
              <v-icon>fab fa-facebook-f</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn
              :loading="processing"
              block
              x-large
              depressed
              color="black white--text"
              dark
              class="text-body-2 text-uppercase ma-0 h-60"
              @click="oauthSignup('apple')"
            >
              <v-icon>fab fa-apple</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <p class="text-body-2 text-uppercase text-center mt-12 mb-6">
          <nuxt-link class="inline--text" to="/forgot">
            Forgot your password
          </nuxt-link>
        </p>
        <p class="text-body-2 text-uppercase text-center">
          Not a member yet?
          <nuxt-link class="inline--text" to="/signup">
            Signup
          </nuxt-link>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import { SignInWithApple } from '@capacitor-community/apple-sign-in'
import types from '@/utils/types'
import { displayError } from '@/utils/swal'

export default {
  middleware: 'notAuthenticated',
  data() {
    return {
      valid: false,
      processing: false,
      password: '',
      passwordRules: [v => !!v || 'Please enter your password'],
      email: '',
      emailRules: [
        v => !!v || 'Please enter your email address',
        v =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Please enter a valid email address'
      ],
      showPassword: false
    }
  },
  async fetch({ store, route, $asa }) {
    const token = await route.query.token
    if (token) {
      try {
        await store.dispatch('authentication/confirm', token)
      } catch (error) {
        $asa.appInsights('Pages: login.vue - method: fetch', error)
      }
    }
  },
  head({ $seo }) {
    return $seo({
      title: 'Login',
      description: 'Log in to your 100ASA account. Email, Google or Facebook login. Reset your password. Not a member yet? Sign up!'
    })
  },
  computed: {
    ...mapState({
      me: state => state.auth.user,
      emailConfirmationToken: state => state.authentication.confirmed
    })
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.processing = true
          const credentials = {
            email: this.email,
            password: this.password
          }
          await this.$auth.loginWith('local', {
            data: credentials
          })
          this.$vuetify.theme.light = !this.me.darkMode
          this.$vuetify.theme.dark = this.me.darkMode
          if (
            this.me &&
            (this.me.isAdmin || this.me.role === types.user.role.curator)
          ) {
            await this.$store.dispatch('notifications/getNotes', {
              page: 1
            })
          }
          await this.$store.dispatch('notifications/getNotifications', {
            page: 1
          })
          await this.$store.dispatch('photos/getNumberOfPhotosInCuration')
          this.clear()
          const redirect = this.$cookies.get('100asa-redirect')
          if (redirect) {
            this.$cookies.remove('100asa-redirect')
            this.$router.push(redirect)
          } else {
            this.$router.push('/')
          }
        } catch (error) {
          this.$asa.appInsights('Pages: login.vue - method: submit', error)
        } finally {
          this.processing = false
        }
      }
    },
    async oauthSignup(provider) {
      try {
        this.processing = true
        await this.$store.dispatch('authentication/cleanCookies')
        if (provider === 'apple') {
          const options = {
            clientId: 'com.100asa.www',
            redirectURI: 'https://100asa.com/callback',
            scopes: 'email name',
          }
          const { response } = await SignInWithApple.authorize(options)
          if (response && response.identityToken) {
            const data = {
              token: response.identityToken,
              provider,
            }
            await this.$store.dispatch('authentication/oauth', data)
            this.$router.push('/dashboard')
          } else {
            displayError('Error social credentials')
          }
        } else {
          await this.$auth.loginWith(provider)
        }
      } catch (error) {
        this.$asa.appInsights('Pages: login.vue - method: oauthSignup', error)
      } finally {
        this.processing = false
      }
    },
    clear() {
      this.$refs.form.reset()
    },
    noop() {}
  }
}
</script>
